import React from 'react';

export const HelpCarousel = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-interval="false"
    >
      <ol className="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      </ol>
      <div className="carousel-inner carousel-content">
        <div className="carousel-item active">
          <h1>Introduction</h1>
          <br></br>
          <div className="d-flex">
            <span className="ml-2 carousal-help-text custom-scroll-intro">
              <b>WELCOME TO OUR INCIDENT NOTIFICATIONS UPDATES SERVICE!</b>
              <br></br>
              <br></br> By subscribing to updates, you will receive the latest
              information regarding current and ongoing incidents that may be
              impacting our Trimble RTX® and Trimble VRS Now™ services. If you
              have any additional questions, please contact our{' '}
              <a
                href="https://positioningservices.trimble.com/en/contact"
                target="_blank"
              >
                customer care team{' '}
              </a>
              .<br></br>
              <br></br>
              <b>
                Type of notifications you may receive from this service
                (Incident Notification Lifecycle):
              </b>
              <br></br>
              <br></br>
              <ul>
                <li>
                  “New Outage” → “Outage Update” notifications →
                  Notification of “Service restored”
                </li>
                <li>
                  “Planned Maintenance” specifying day and time → “Planned
                  Maintenance Reminder” notification →
                  Notification of “Service restored”
                </li>
              </ul>
              <br></br> <b>To Subscribe</b>
              <br></br> <u>STEP 1: Select Services</u> <br></br>Under “Select
              Services” you will find the dropdown list of specific services we
              continuously monitor. <br></br>
              <br></br>
              <u>STEP 2: Revise selection</u>
              <br></br> By selecting the services of your preference and
              clicking “Subscribe”, you will see your preferences listed under
              the section “Subscribed to” where you can remove or keep adding
              elements from the dropdown list. <br></br>
              <br></br>
              <u>STEP 3: Confirm selection</u> <br></br>Once you are done
              creating your list of preferred services, click on the “Update”
              button for subscribing to these specific updates. <br></br>
              <br></br>
              <u>STEP 4: Done!</u> <br></br>After the preferences are processed,
              you can “log out” from your profile at the top right of the page.
              <br></br>
              <br></br>
              <b>To Unsubscribe</b> <br></br>
              <u>From specific services</u> <br></br>By Clicking on the “X” next
              to the service listed in the “Subscribed to” section or removing
              the services from the dropdown list from “Select Services” and
              clicking the "Update" <br></br>
              <br></br>
              <u>From ALL Selected services</u> <br></br>Just click on the
              “Unsubscribe” button.
              <br></br>
            </span>
          </div>
          <br></br>
          <br></br>
        </div>
        <div className="carousel-item">
          <h1>Subscribe</h1>
          <br></br>
          <div className="d-flex">
            <span className="carousal-help-text ml-2">
              A user can subscribe to any of the listed services to get updates
              on those services alone. Atleast one service needs to be selected
              to proceed with subscription.
            </span>
          </div>
          <br></br>
          <img
            className="d-block w-100"
            src={require('../assets/subscriber.gif')}
            alt="Second Slide"
            style={{ padding: '10px' }}
          />
        </div>
        <div className="carousel-item">
          <h1>Update Subscription</h1>
          <br></br>
          <div className="d-flex">
            <span className="carousal-help-text ml-2">
              A user can modify the service list to get updates on the new
              services. Atleast one service needs to be selected to proceed with
              update subscription.
            </span>
          </div>
          <br></br>
          <img
            className="d-block w-100"
            src={require('../assets/updateSubscriber.gif')}
            alt="Third Slide"
            style={{ padding: '10px' }}
          />
        </div>
        <div className="carousel-item">
          <h1>Unsubscribe</h1>
          <br></br>
          <div className="d-flex">
            <span className="carousal-help-text ml-2">
              A user can unsubscribe from all the selected services at any time.
              The user will stop receiving any latest information regarding
              current and ongoing incidents.
            </span>
          </div>
          <br></br>
          <img
            className="d-block w-100"
            src={require('../assets/deleteSubscriber.gif')}
            alt="Fourth Slide"
            style={{ padding: '10px' }}
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        data-target="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        data-target="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};
