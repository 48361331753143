import { StorageTypes } from '../../types/StorageTypes';

export const storeLocalData = (...data: StorageTypes[]) => {
  for (let i = 0; i < data.length; i++) {
    localStorage.setItem(data[i].key, data[i].value);
  }
};

export const getLocalData = (key: string) => {
  return localStorage.getItem(key);
};

export const clearLocalData = () => {
  localStorage.clear();
};

export class localConstants {
  public static readonly codeVerifier = 'code_verifier';
  public static readonly codeChallenge = 'code_challenge';
  public static readonly code = 'code';
}
