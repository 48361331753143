import configDev from './config.dev.json';
import configProd from './config.prod.json';
import configStg from './config.stg.json';
import configLocal from './config.local.json';

const getConfig = (): any => {
  const environment = process.env.REACT_APP_ENV;
  switch (environment) {
    case 'dev':
      return configDev;
    case 'prod':
      return configProd;
    case 'stg':
      return configStg;
    default:
      return configLocal;
  }
};

export default getConfig();
