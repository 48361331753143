import { Routes, Route, Navigate } from 'react-router-dom';
import { Error } from '../components/Error';

import RouterPath from 'config/routerPath';
import { Layout } from './Layout';
import { TidLogin } from './oauth/TidLogin';
import { RequireAuth } from 'routes/oauth/RequireAuth';
import SubscriberForm from 'components/SubscriberForm';
import { Logout } from 'components/Logout';
import { AuthToken } from './oauth/AuthToken';

export const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path={RouterPath.PUBLIC_ROUTE.loginTIDPath}
          element={<TidLogin />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.errorUrlPath}
          element={<Error />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.logoutPath}
          element={<Logout />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.redirectUrlPath}
          element={<AuthToken />}
        ></Route>
        <Route element={<RequireAuth />}>
          <Route
            path={RouterPath.PRIVATE_ROUTE.subscribe}
            element={<SubscriberForm />}
          ></Route>
          <Route
            path="*"
            element={
              <Navigate
                to={RouterPath.PRIVATE_ROUTE.subscribe}
                replace
              ></Navigate>
            }
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
};
