import { Modal } from "@trimbleinc/modus-react-bootstrap";
import { HelpCarousel } from "./HelpCarousel";

interface IPropTypesHelpModal {
  show: boolean;
  handleClose: () => void;
}
export const HelpModal = ({ show, handleClose }: IPropTypesHelpModal) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Help Guide</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HelpCarousel />
      </Modal.Body>
    </Modal>
  );
};
