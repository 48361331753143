import {
  Button,
  Alert,
  OverlayTrigger,
  Tooltip,
  Message,
} from '@trimbleinc/modus-react-bootstrap';
import { deleteSubscriber, updateSubscriber } from 'Services/GroupManagement';
import { createRequest, updateRequest } from 'common/requestBuilder';
import React, { useState } from 'react';
import { CenteredSpinnerCircle } from './CenteredSpinnerCircle';

interface Selected {
  label: string;
  nodeId: number;
  statuscastServiceId: number;
}

interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}

interface NodeWithHidden {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: NodeWithHidden[];
  isSelected: boolean;
  isHidden: boolean;
  isHiddenCount: number;
  childCount: number;
}

interface UnsubscriberButtonComponentProps {
  selected: Selected[];
  stateData: Node[];
  email: string;
  onSuccessfulSubscribe: () => void;
  hiddenStateData: NodeWithHidden[] | undefined;
  isHiddenDataAvailable: boolean;
}

const UnsubscribeButton: React.FC<UnsubscriberButtonComponentProps> = (
  props: UnsubscriberButtonComponentProps
) => {
  const [showAlert, setShowAlert] = useState(false);
  const [enableButton, setButton] = useState(false);
  const [isError, setError] = useState<boolean>(false);

  async function handleUpdate(): Promise<void> {
    if (props.selected.length < 1) {
      setShowAlert(true);
    } else if (props.email.endsWith('.ru')) {
      setError(true);
    } else {
      setButton(true);
      const request = createRequest(
        props.selected,
        props.stateData,
        props.email,
        props.hiddenStateData
      );

      const response = await updateSubscriber(
        request,
        setButton,
        setError,
        'isReplace=true'
      );

      if (response != null) {
        if (response.status === 200) {
          props.onSuccessfulSubscribe();
          setButton(false);
        }
      } else {
        setButton(false);
      }
    }
  }

  async function handleDelete(): Promise<void> {
    setButton(true);
    let response;

    if (props.isHiddenDataAvailable) {
      const request = updateRequest(props.email, props.hiddenStateData);

      response = await updateSubscriber(
        request,
        setButton,
        setError,
        'isReplace=true'
      );
    } else {
      const request = props.email;

      response = await deleteSubscriber(request, setButton, setError);
    }

    if (response != null) {
      if (response.status === 200) {
        props.onSuccessfulSubscribe();
        setButton(false);
      }
    } else {
      setButton(false);
    }
  }

  return (
    <div>
      {enableButton && (
        <div>
          <CenteredSpinnerCircle loadingText='Processing...' />
          <br></br>
        </div>
      )}

      {isError && (
        <Alert variant='danger' onClose={() => setError(false)} dismissible>
          <i className='modus-icons notranslate alert-icon' aria-hidden='true'>
            warning
          </i>
          Error occured! Please Contact Administrator
        </Alert>
      )}

      {showAlert && (
        <Alert variant='danger' onClose={() => setShowAlert(false)} dismissible>
          <i className='modus-icons notranslate alert-icon' aria-hidden='true'>
            warning
          </i>
          Please select at least one Service.
        </Alert>
      )}
      <div className='d-flex justify-content-center '>
        <span>
          <OverlayTrigger
            key='overlay1'
            placement='top'
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip
                id='btntooltip'
                className={
                  !enableButton && !isError
                    ? 'enable-tooltip'
                    : 'disable-tooltip'
                }
              >
                On clicking this you will be Unsubscribed from all Services
              </Tooltip>
            }
          >
            <Button
              variant='secondary'
              className='mr-2'
              onClick={handleDelete}
              disabled={enableButton}
            >
              Unsubscribe
            </Button>
          </OverlayTrigger>
        </span>

        <span>
          <Button
            variant='primary'
            className='mr-2'
            onClick={handleUpdate}
            style={{ marginLeft: '20px' }}
            disabled={enableButton}
          >
            Update
          </Button>
        </span>

        <br></br>
        <br></br>
        <br></br>
      </div>
      <div>
        <Message
          icon='info'
          variant='primary'
          message="By clicking on 'Update', you agree to receive incidents updates from Trimble Positioning Services. You can unsubscribe at anytime."
        ></Message>
      </div>
    </div>
  );
};

export default UnsubscribeButton;
