import { Navigate, Outlet } from 'react-router-dom';
import {
  getSessionData,
  sessionConstants,
} from 'components/storage/sessionStorage';
import routerPath from 'config/routerPath';
import NavBar from '../../components/navBar';
export const RequireAuth = () => {
  const isTokenValid = (): boolean => {
    const token = getSessionData(sessionConstants.token);
    const expiration = getSessionData(sessionConstants.expiry);
    if (token !== null && expiration !== null) {
      const expiry = new Date(expiration);
      const current = new Date();
      if (current.getTime() < expiry.getTime()) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return isTokenValid() ? (
    <div>
      <NavBar />
      <Outlet />
    </div>
  ) : (
    <Navigate to={routerPath.PUBLIC_ROUTE.loginTIDPath} replace />
  );
};
