import { AxiosRequestConfig } from 'axios';
import { executeRequest } from './httpService';
import { TIDClient } from './httpClients';
import path from 'config/urlPath';
import { OauthTypes } from 'types/OauthTypes';
import config from 'config';
import { GetAuthTokenError } from 'CustomErrors/getAuthTokenError';

const generateParams = (params: OauthTypes) => {
  const queryParams = new URLSearchParams();
  queryParams.append('grant_type', 'authorization_code');
  queryParams.append('client_id', config.AUTH_APP_SERVICE.ClientId);
  queryParams.append(
    'redirect_uri',
    config.AUTH_APP_SERVICE.WEBAPP_URL + path.AUTH_API_SERVICE_URL.redirect
  );
  queryParams.append('tenantDomain', 'Trimble.com');
  queryParams.append('code', params.code);
  queryParams.append('code_verifier', params.code_verifier);
  return queryParams;
};

export const getToken = async (params: OauthTypes) => {
  try {
    const options: AxiosRequestConfig = {
      url: path.AUTH_API_SERVICE_URL.oauthToken,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: generateParams(params),
    };
    const response = await executeRequest(TIDClient, options);
    return response.data;
  } catch (error) {
    throw new GetAuthTokenError(error);
  }
};
