import React from 'react';
import { CenteredSpinnerCircle } from '../../components/CenteredSpinnerCircle';
import { Navigate, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import {
  storeLocalData,
  getLocalData,
  clearLocalData,
  localConstants,
} from 'components/storage/localStorage';
import { addSeconds } from '../../common/commonFunction';
import {
  sessionConstants,
  storeSessionData,
} from 'components/storage/sessionStorage';
import routerPath from 'config/routerPath';
import { getToken } from 'Services/AuthenticationService';

export const AuthToken = () => {
  const navigate = useNavigate();
  const getParams = new URLSearchParams(window.location.search);
  const code = getParams.get('code');
  const [token, setToken] = React.useState('');
  let checkHandle = true;

  const updateSessionStorageWithTokenData = (tokenData: any) => {
    if (tokenData) {
      var expirationDate = addSeconds(tokenData.expires_in);
      var user: { email: string } = jwt(tokenData.id_token);
      storeSessionData(
        {
          key: sessionConstants.token,
          value: tokenData.access_token,
        },
        {
          key: sessionConstants.expiry,
          value: expirationDate.toString(),
        },
        {
          key: sessionConstants.idToken,
          value: tokenData.id_token,
        },
        {
          key: sessionConstants.refreshToken,
          value: tokenData.refresh_token,
        },
        {
          key: sessionConstants.email,
          value: user.email,
        }
      );
      clearLocalData();
      setToken(tokenData.access_token);
    } else {
      navigate(`/${routerPath.PUBLIC_ROUTE.errorUrlPath}`);
    }
  };
  const handleToken = async () => {
    checkHandle = false;
    const code = getLocalData(localConstants.code);
    const codeVerifier = getLocalData(localConstants.codeVerifier);
    let tokenDataFromBackend = await getToken({
      code: code ? code : '',
      code_verifier: codeVerifier ? codeVerifier : '',
    });
    updateSessionStorageWithTokenData(tokenDataFromBackend);
  };

  React.useEffect(() => {
    storeLocalData({
      key: localConstants.code,
      value: code!,
    });
    checkHandle && (async () => await handleToken())();
  }, []);

  return (
    <div>
      {token ? (
        <>
          <Navigate to="/"></Navigate>
        </>
      ) : (
        <div>
          <br></br>
          <br></br>
          <br></br>
          <CenteredSpinnerCircle loadingText="Logging In"></CenteredSpinnerCircle>
        </div>
      )}
    </div>
  );
};
