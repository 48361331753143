// import React, { useState } from 'react';
import EmailInput from './EmailInput';
import { TrimbleLogo } from './../common/constants';
import FetchResponse from './FetchResponse';
import { useEffect, useState } from 'react';
import { getSessionData, sessionConstants } from './storage/sessionStorage';
import { userJwt } from 'types/userJwt';
import jwt from 'jwt-decode';
import UnauthorizedPage from './UnauthorizedPage';
const SubscriberForm = () => {
  const [isRussianEmail, setIsRussianEmail] = useState(true);

  useEffect(() => {
    // code to get user name to display profile details
    const idToken = getSessionData(sessionConstants.idToken);
    var user: userJwt = jwt(idToken !== null ? idToken : '');
    if (user.email !== undefined && user.email.endsWith('.ru')) {
      setIsRussianEmail(true);
    } else {
      setIsRussianEmail(false);
    }
  }, []);

  return (
    <>
      {isRussianEmail ? (
        <UnauthorizedPage />
      ) : (
        <div className='d-flex flex-column align-items-center justify-content-center container'>
          <div className='d-flex justify-content-center custom-title-line-breaks'>
            <img
              src={TrimbleLogo.TAPLogo}
              alt='TAP Logo'
              className='ter-navbar-top__logo'
            ></img>
            <p className='ter-navbar-top__division-name'>
              Positioning Services
            </p>
          </div>
          <br></br>
          <b style={{ textAlign: 'center' }}>
            WELCOME TO OUR INCIDENT NOTIFICATIONS UPDATES SERVICE!
          </b>{' '}
          <br></br>
          <div className='box-container'>
            <span>
              <p>
                By subscribing to updates, you will receive the latest
                information regarding current and ongoing incidents that may be
                impacting our Trimble RTX® and Trimble VRS Now™ services. If you
                have any additional questions, please contact our{' '}
                <a
                  href='https://positioningservices.trimble.com/en/contact'
                  target='_blank'
                >
                  customer care team.{' '}
                </a>
                <br></br>
              </p>
            </span>
            <span>
              <p>
                This service sends notifications for few incident types, that
                includes "New Outage" and "Planned Maintenance." For each
                incident type, updates are provided, and notifications are sent
                when the service is restored.{' '}
              </p>
            </span>
          </div>
          <br></br>
          <EmailInput inputMode='input' label='E-mail' size='lg' />
          <br></br>
          <FetchResponse />
        </div>
      )}
    </>
  );
};

export default SubscriberForm;
