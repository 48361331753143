import React, { useState } from 'react';
import config from 'config';
import path from '../config/urlPath';
import { getSessionData, sessionConstants } from './storage/sessionStorage';
import jwt from 'jwt-decode';
import { userJwt } from 'types/userJwt';
import {
  Dropdown,
  Nav,
  NavItem,
  Navbar,
} from '@trimbleinc/modus-react-bootstrap';
import { TrimbleLogo } from './../common/constants';
import { HelpModal } from './HelpModal';

const NavbarComponent: React.FC = () => {
  const [showHelpGuide, setShowHelpGuide] = useState(false);

  const handleClose = () => setShowHelpGuide(false);
  const handleShow = () => setShowHelpGuide(true);

  // code to get user name to display profile details
  const idToken = getSessionData(sessionConstants.idToken);
  var user: userJwt = jwt(idToken !== null ? idToken : '');
  // call this method for logout
  const handleLogout = () => {
    window.open(
      config.AUTH_APP_SERVICE.LOGOUT_URL.replace(
        '{id_token_hint}',
        getSessionData(sessionConstants.idToken)
      ).replace(
        '{post_logout_redirect_uri}',
        config.AUTH_APP_SERVICE.WEBAPP_URL + path.AUTH_API_SERVICE_URL.logout
      ),
      '_self'
    );
  };
  return (
    <>
      <Nav className="navbar navbar-blue sticky-top d-flex justify-content-between">
        <div>
          <img
            src={TrimbleLogo.TrimbleLogoRev}
            width="107"
            height="25"
            className="img-fluid d-none d-sm-block"
            alt="home"
          />
          <img
            src={TrimbleLogo.TrimbleIconRev}
            className="d-block d-sm-none"
            height="25"
            width="25"
            alt="home"
          />
        </div>

        <div className="d-flex">
          <NavItem
            className={
              showHelpGuide ? 'cursor px-3 bg-trimble-blue' : 'cursor px-3'
            }
            id="help"
            onClick={handleShow}
          >
            <div className="d-flex mt-2 ">
              <i
                className="modus-icons"
                style={{ color: 'white' }}
                aria-hidden="true"
              >
                help_outlined
              </i>

              <span className="px-2 text-white ">Help</span>
            </div>
          </NavItem>
          <Dropdown className="ml-auto mr-2 ">
            <Dropdown.Toggle
              variant="primary"
              className="navbar-blue mt-1"
              bsPrefix="border-0"
            >
              <i className="modus-icons material-icons">person_account</i>
            </Dropdown.Toggle>
            <Dropdown.Menu align={'right'}>
              <Dropdown.ItemText>
                {`${user.given_name} ${user.family_name}`}
              </Dropdown.ItemText>
              <Dropdown.ItemText>{` ${user.email}`}</Dropdown.ItemText>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Nav>
      <HelpModal show={showHelpGuide} handleClose={handleClose} />
    </>
  );
};

export default NavbarComponent;
