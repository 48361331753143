const routerPath = {
  PUBLIC_ROUTE: {
    loginTIDPath: 'TIDLogin',
    redirectUrlPath: 'redirect',
    errorUrlPath: 'error',
    logoutPath: 'logout',
  },
  PRIVATE_ROUTE: {
    subscribe: '/',
  },
};

export default routerPath;
