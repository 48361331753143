import { getSessionData, sessionConstants } from './storage/sessionStorage';
import jwt from 'jwt-decode';
import { userJwt } from 'types/userJwt';
import { getSubscriber } from 'Services/GroupManagement';
import { useEffect, useState } from 'react';
import SubscribeButton from './SubscribeButton';
import UnsubscribeButton from './UnsubscribeButton';

interface Selected {
  label: string;
  nodeId: number;
  statuscastServiceId: number;
}

interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}

interface NodeWithHidden {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: NodeWithHidden[];
  isSelected: boolean;
  isHidden: boolean;
  isHiddenCount: number;
  childCount: number;
}

interface MarkEntryComponentProps {
  selected: Selected[];
  onCheckboxChange: (nodeId: number, checked: boolean) => void;
  onGetSubscriberFetch: (nodeId: number, checked: boolean) => void;
  stateData: Node[];
  hiddenStateData: NodeWithHidden[] | undefined;
}

const MarkPreviousEntry: React.FC<MarkEntryComponentProps> = ({
  selected,
  onCheckboxChange,
  onGetSubscriberFetch,
  stateData,
  hiddenStateData,
}) => {
  const [isAlreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);
  const [isAlreadySubscribedwithHidden, setAlreadySubscribedforHidden] =
    useState<boolean>(false);

  const [renderKey, setRenderKey] = useState(0);

  const handleSuccessfulSubscribe = () => {
    setRenderKey(renderKey + 1);
  };
  const idToken = getSessionData(sessionConstants.idToken);
  var user: userJwt = jwt(idToken !== null ? idToken : '');

  const queryParams = {
    email: `${user.email}`,
  };

  const findNodeByServiceId = (
    serviceId: number,
    nodes: Node[]
  ): Node | undefined => {
    for (const node of nodes) {
      if (node.statuscastId === serviceId) {
        return node;
      } else if (node.children) {
        const foundNode = findNodeByServiceId(serviceId, node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  };

  const findNodeWIthHiddenByServiceId = (
    serviceId: number,
    nodes: NodeWithHidden[] | undefined
  ): NodeWithHidden | undefined => {
    if (nodes) {
      for (const node of nodes) {
        if (node.statuscastId === serviceId) {
          return node;
        } else if (node.children) {
          const foundNode = findNodeWIthHiddenByServiceId(
            serviceId,
            node.children
          );
          if (foundNode) {
            return foundNode;
          }
        }
      }
    }

    return undefined;
  };

  const resetSelectedState = (nodes: Node[]): void => {
    for (const node of nodes) {
      if (node.isSelected) {
        onCheckboxChange(node.nodeId, false);
      }
      if (node.children) {
        resetSelectedState(node.children);
      }
    }
  };

  const resetSelectedStateforHiddenNode = (
    nodes: NodeWithHidden[] | undefined
  ): void => {
    if (nodes) {
      for (const node of nodes) {
        if (node.isSelected && !node.isHidden) {
          onGetSubscriberFetch(node.nodeId, false);
        }
        if (node.children) {
          resetSelectedStateforHiddenNode(node.children);
        }
      }
    }
  };

  const countSelectedVisibleNodes = (
    nodes: NodeWithHidden[] | undefined
  ): number | undefined => {
    let count = 0;

    const findNodes = (currentNodes: NodeWithHidden[] | undefined) => {
      if (currentNodes) {
        currentNodes.forEach((node) => {
          if (node.isSelected && !node.isHidden) {
            count++;
          }
          if (node.children) {
            findNodes(node.children);
          }
        });
      }
    };

    findNodes(nodes);

    return count;
  };

  useEffect(() => {
    const fetchSubscriber = async () => {
      const response = await getSubscriber(queryParams);
      if (response.data.subscribers.length > 0) {
        const serviceIDSToFind = response.data.subscribers[0].statusCastServiceIds;
        resetSelectedStateforHiddenNode(hiddenStateData);
        serviceIDSToFind.forEach((serviceId: number) => {
          const nodeToCheckforHidden = findNodeWIthHiddenByServiceId(
            serviceId,
            hiddenStateData
          );
          if (nodeToCheckforHidden) {
            onGetSubscriberFetch(nodeToCheckforHidden.nodeId, true);
          }
        });

        const selectedVisibleCount = countSelectedVisibleNodes(hiddenStateData);

        if (selectedVisibleCount && selectedVisibleCount > 0) {
          if (serviceIDSToFind.length != selectedVisibleCount) {
            setAlreadySubscribedforHidden(true);
          }
          setAlreadySubscribed(true);
          serviceIDSToFind.forEach((serviceId: number) => {
            const nodeToCheck = findNodeByServiceId(serviceId, stateData);
            if (nodeToCheck) {
              onCheckboxChange(nodeToCheck.nodeId, true);
            }
          });
        } else {
          setAlreadySubscribedforHidden(true);
          setAlreadySubscribed(false);
          resetSelectedStateforHiddenNode(hiddenStateData);
          resetSelectedState(stateData);
        }
      } else {
        resetSelectedStateforHiddenNode(hiddenStateData);
        resetSelectedState(stateData);
        setAlreadySubscribed(false);
      }
    };
    fetchSubscriber();
  }, [renderKey]);


  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <br></br>
      <br></br>
      {isAlreadySubscribed ? (
        <UnsubscribeButton
          key={renderKey}
          onSuccessfulSubscribe={handleSuccessfulSubscribe}
          selected={selected}
          stateData={stateData}
          email={user.email}
          hiddenStateData={hiddenStateData}
          isHiddenDataAvailable={isAlreadySubscribedwithHidden}
        />
      ) : (
        <SubscribeButton
          key={renderKey}
          onSuccessfulSubscribe={handleSuccessfulSubscribe}
          selected={selected}
          stateData={stateData}
          email={user.email}
          hiddenStateData={hiddenStateData}
          isHiddenDataAvailable={isAlreadySubscribedwithHidden}
        />
      )}

      <br></br>
    </div>
  );
};

export default MarkPreviousEntry;
