import axios from 'axios';
import config from 'config';

export const ServiceHttpClient = axios.create({
  baseURL: config.SERVICE_MANAGEMENT.BASE_URL,
});

export const TIDClient = axios.create({
  baseURL: config.AUTH_APP_SERVICE.BASE_URL,
});

export const GroupHttpClient = axios.create({
  baseURL: config.GROUP_MANAGEMENT.BASE_URL,
});