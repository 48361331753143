const getPath = {
  AUTH_API_SERVICE_URL: {
    redirect: 'redirect',
    oauthToken: 'oauth/token',
    logout: 'logout',
  },
  SERVICE_MANAGEMENT_URL: {
    getAllServices: 'getallservices?triggers=true',
  },
  GROUP_MANAGEMENT_URL: {
    Subscriber: 'subscriber',
  },
};

export default getPath;
