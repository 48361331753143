export const generateRandomString = (length: number): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const addSeconds = (numOfSeconds: number, date = new Date()): Date => {
  date.setSeconds(date.getSeconds() + numOfSeconds);
  return date;
};
