import React from 'react';
import { clearSessionData } from './storage/sessionStorage';
import { useNavigate } from 'react-router-dom';
import routerPath from '../config/routerPath';
import { CenteredSpinnerCircle } from '../components/CenteredSpinnerCircle';

export const Logout = () => {
  const Styles = {
    container: {
      height: '100vh',
    },
  };

  const navigate = useNavigate();
  React.useEffect(() => {
    clearSessionData();
    navigate(routerPath.PRIVATE_ROUTE.subscribe);
  }, []);

  return (
    <div
      className="d-flex align-items-center justify-content-center w-100"
      style={Styles.container}
    >
      <CenteredSpinnerCircle loadingText="Loading" />
    </div>
  );
};
