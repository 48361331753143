import { AxiosRequestConfig } from 'axios';
import { executeRequest } from './httpService';
import { GroupHttpClient } from './httpClients';
import path from 'config/urlPath';
import { getSessionData } from 'components/storage/sessionStorage';
import { GetSubscriberError } from 'CustomErrors/getSubscriberError';
import { CreateRequest } from 'types/CreateRequest';

export const getSubscriber = async (queryParams?: Record<string, string>) => {
  try {
    const appToken = getSessionData('token');

    // Build the query parameters
    const searchParams = new URLSearchParams(queryParams);

    const options: AxiosRequestConfig = {
      url: `${path.GROUP_MANAGEMENT_URL.Subscriber}?${searchParams.toString()}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${appToken}`,
      },
    };
    const response = await executeRequest(GroupHttpClient, options);
    return response;
  } catch (error) {
    throw new GetSubscriberError(error);
  }
};

export const updateSubscriber = async (
  createSubscriber: CreateRequest,
  setButton: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  queryParam?: string
) => {
  try {
    const appToken = getSessionData('token');

    const options: AxiosRequestConfig = {
      url: `${path.GROUP_MANAGEMENT_URL.Subscriber}?isUI=true${queryParam ? `&${queryParam}` : ''}`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${appToken}`,
      },
      data: createSubscriber,
    };
    const response = await executeRequest(GroupHttpClient, options);
    return response;
  } catch (error) {
    setButton(false);
    setError(true);
    return null;
  }
};

export const deleteSubscriber = async (
  deleteSubscriberEmail: string,
  setButton: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const appToken = getSessionData('token');

    const options: AxiosRequestConfig = {
      url: `${path.GROUP_MANAGEMENT_URL.Subscriber}?email=${deleteSubscriberEmail}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${appToken}`,
      },
    };
    const response = await executeRequest(GroupHttpClient, options);
    return response;
  } catch (error) {
    setButton(false);
    setError(true);
    return null;
  }
};
