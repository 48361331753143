import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpRequestError } from '../CustomErrors/httpRequestError';

export const executeRequest = async (
  httpClient: AxiosInstance,
  httpOptions: AxiosRequestConfig
) => {
  try {
    const response = await httpClient.request(httpOptions);
    return response;
  } catch (err) {
    throw new HttpRequestError(err);
  }
};
