import { Alert, Button } from '@trimbleinc/modus-react-bootstrap';
import { updateSubscriber } from 'Services/GroupManagement';
import { createRequest } from 'common/requestBuilder';
import React, { useState } from 'react';
import { CenteredSpinnerCircle } from './CenteredSpinnerCircle';
interface Selected {
  label: string;
  nodeId: number;
  statuscastServiceId: number;
}

interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}
interface NodeWithHidden {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: NodeWithHidden[];
  isSelected: boolean;
  isHidden: boolean;
  isHiddenCount: number;
  childCount: number;
}
interface SubscriberButtonComponentProps {
  selected: Selected[];
  stateData: Node[];
  email: string;
  onSuccessfulSubscribe: () => void;
  hiddenStateData: NodeWithHidden[] | undefined;
  isHiddenDataAvailable: boolean;
}

const SubscribeButton: React.FC<SubscriberButtonComponentProps> = (
  props: SubscriberButtonComponentProps
) => {
  const [showAlert, setShowAlert] = useState(false);
  const [enableButton, setButton] = useState(false);
  const [isError, setError] = useState<boolean>(false);

  async function handleCreate(): Promise<void> {
    if (props.selected.length < 1) {
      setShowAlert(true);
    } else if (props.email.endsWith('.ru')) {
      setError(true);
    } else {
      const request = createRequest(
        props.selected,
        props.stateData,
        props.email,
        props.hiddenStateData
      );
      setButton(true);

      let response;

      if (props.isHiddenDataAvailable) {
        //update subscriber will be performed in backend
        response = await updateSubscriber(
          request,
          setButton,
          setError,
          'isReplace=true'
        );
      } else {
        //add subscriber will be performed in backend
        response = await updateSubscriber(request, setButton, setError);
      }

      if (response != null) {
        if (response.status === 200) {
          props.onSuccessfulSubscribe();
          setButton(false);
        }
      } else {
        setButton(false);
      }
    }
  }
  return (
    <div>
      {enableButton && (
        <div>
          <CenteredSpinnerCircle loadingText='Processing...' />
          <br></br>
        </div>
      )}

      {isError && (
        <Alert variant='danger' onClose={() => setError(false)} dismissible>
          <i className='modus-icons notranslate alert-icon' aria-hidden='true'>
            warning
          </i>
          Error occured! Please Contact Administrator
        </Alert>
      )}

      {showAlert && (
        <Alert variant='danger' onClose={() => setShowAlert(false)} dismissible>
          <i className='modus-icons notranslate alert-icon' aria-hidden='true'>
            warning
          </i>
          Please select at least one Service.
        </Alert>
      )}

      {showAlert ? (
        <Button
          variant='primary'
          className='mr-2'
          onClick={handleCreate}
          style={{ marginLeft: '34%' }}
          disabled={enableButton}
        >
          Subscribe
        </Button>
      ) : (
        <Button
          variant='primary'
          className='mr-2'
          style={isError ? { marginLeft: '34%' } : { marginLeft: '25px' }}
          onClick={handleCreate}
          disabled={enableButton}
        >
          Subscribe
        </Button>
      )}
    </div>
  );
};

export default SubscribeButton;
