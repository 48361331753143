import { CreateRequest } from 'types/CreateRequest';

interface Selected {
  label: string;
  nodeId: number;
  statuscastServiceId: number;
}

interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}

interface NodeWithHidden {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  children?: NodeWithHidden[];
  isSelected: boolean;
  isHidden: boolean;
  isHiddenCount: number;
  childCount: number;
}

const findNodeByServiceId = (
  serviceId: number,
  nodes: Node[]
): Node | undefined => {
  for (const node of nodes) {
    if (node.statuscastId === serviceId) {
      return node;
    } else if (node.children) {
      const foundNode = findNodeByServiceId(serviceId, node.children);
      if (foundNode) {
        return foundNode;
      }
    }
  }
};

const findHiddenNodeByServiceId = (
  selectedServiceId: number,
  nodes: NodeWithHidden[] = []
): NodeWithHidden | undefined => {
  const node = nodes.find((node) => node.statuscastId === selectedServiceId);
  if (node) return node;

  for (const childNode of nodes) {
    const foundNode = findHiddenNodeByServiceId(
      selectedServiceId,
      childNode.children
    );
    if (foundNode) return foundNode;
  }

  return undefined;
};

const getSelectedNodes = (nodes: Node | undefined): Node[] => {
  const selectedNodes: Node[] = [];

  const findNodes = (currentNodes: Node | undefined) => {
    if (currentNodes) {
      if (currentNodes.isSelected && currentNodes.children == undefined) {
        selectedNodes.push(currentNodes);
      }

      if (currentNodes.children) {
        for (const node of currentNodes.children) findNodes(node);
      }
    }
  };

  findNodes(nodes);

  return selectedNodes;
};

const getSelectedHiddenNodes = (
  nodes: NodeWithHidden | undefined
): NodeWithHidden[] => {
  const selectedHiddenNodes: NodeWithHidden[] = [];

  const findNodes = (currentNodes: NodeWithHidden | undefined) => {
    if (currentNodes) {
      if (currentNodes.isSelected && currentNodes.isHidden) {
        selectedHiddenNodes.push(currentNodes);
      }

      if (currentNodes.children) {
        for (const node of currentNodes.children) findNodes(node);
      }
    }
  };

  findNodes(nodes);

  return selectedHiddenNodes;
};

const getAllSelectedHiddenNodes = (
  nodes: NodeWithHidden[] | undefined
): NodeWithHidden[] | undefined => {
  const allSelectedHiddenNodes: NodeWithHidden[] = [];

  const findNodes = (currentNodes: NodeWithHidden[] | undefined) => {
    if (currentNodes) {
      currentNodes.forEach((node) => {
        if (node.isSelected && node.isHidden) {
          allSelectedHiddenNodes.push(node);
        }
        if (node.children) {
          findNodes(node.children);
        }
      });
    }
  };

  findNodes(nodes);

  return allSelectedHiddenNodes;
};

export const createRequest = (
  selected: Selected[],
  stateData: Node[],
  email: string,
  hiddenStateData: NodeWithHidden[] | undefined
): CreateRequest => {
  let result: CreateRequest = {
    statuscastServiceId: [],
    statuscastGroupId: [],
    displayName: [],
    emails: [],
  };
  result.emails.push(email);
  const allSelectedHiddenNodes = getAllSelectedHiddenNodes(hiddenStateData);
  let selectedHiddenNodesForEntry: NodeWithHidden[] = [];

  selected.forEach((selectedItem) => {
    const matchingNode = findNodeByServiceId(
      selectedItem.statuscastServiceId,
      stateData
    );
    const selectedNodes = getSelectedNodes(matchingNode);

    const matchingNodeChild = findHiddenNodeByServiceId(
      selectedItem.statuscastServiceId,
      hiddenStateData
    );

    const matchingNodeChildCount = matchingNodeChild?.childCount;
    const matchingNodeIsHiddenCount = matchingNodeChild?.isHiddenCount;
    const selectedHiddenNodes = getSelectedHiddenNodes(matchingNodeChild);

    let vissibleNodeLength = selectedNodes.length;
    let hiddenSelectedNodeCount = selectedHiddenNodes.length;
    let allNodeCount = 0;

    if (matchingNodeChildCount) {
      allNodeCount = matchingNodeChildCount;
    }

    if (hiddenSelectedNodeCount > 0) {
      selectedHiddenNodesForEntry.push(...selectedHiddenNodes);
    }

    if (hiddenSelectedNodeCount == 0) {
      if (matchingNodeIsHiddenCount && matchingNodeIsHiddenCount > 0) {
        for (const node of selectedNodes) {
          result.statuscastServiceId.push(node.statuscastId);
          result.statuscastGroupId.push(node.statuscastGroupId);
          result.displayName.push(node.label);
        }
      } else {
        if (matchingNode) {
          result.statuscastServiceId.push(matchingNode.statuscastId);
          result.statuscastGroupId.push(matchingNode.statuscastGroupId);
          result.displayName.push(matchingNode.label);
        }
      }
    } else if (vissibleNodeLength + hiddenSelectedNodeCount == allNodeCount) {
      if (matchingNode) {
        result.statuscastServiceId.push(matchingNode.statuscastId);
        result.statuscastGroupId.push(matchingNode.statuscastGroupId);
        result.displayName.push(matchingNode.label);
      }
    } else {
      for (const node of selectedNodes) {
        result.statuscastServiceId.push(node.statuscastId);
        result.statuscastGroupId.push(node.statuscastGroupId);
        result.displayName.push(node.label);
      }

      for (const node of selectedHiddenNodes) {
        result.statuscastServiceId.push(node.statuscastId);
        result.statuscastGroupId.push(node.statuscastGroupId);
        result.displayName.push(node.label);
      }
    }
  });

  // Convert the array to a Set to remove duplicates
  const uniqueSelectedHiddenNodes = [...new Set(selectedHiddenNodesForEntry)];

  // Get all nodes that are in allSelectedHiddenNodes but not in selectedHiddenNodesForEntry
  const nodesNotInSelectedHiddenNodes = allSelectedHiddenNodes?.filter(
    (node) => {
      // Check if the node is not in selectedHiddenNodes
      return !uniqueSelectedHiddenNodes.some(
        (selectedNode) => selectedNode.nodeId === node.nodeId
      );
    }
  );

  if (nodesNotInSelectedHiddenNodes) {
    for (const node of nodesNotInSelectedHiddenNodes) {
      result.statuscastServiceId.push(node.statuscastId);
      result.statuscastGroupId.push(node.statuscastGroupId);
      result.displayName.push(node.label);
    }
  }
  return result;
};

export const updateRequest = (
  email: string,
  hiddenStateData: NodeWithHidden[] | undefined
): CreateRequest => {
  let result: CreateRequest = {
    statuscastServiceId: [],
    statuscastGroupId: [],
    displayName: [],
    emails: [],
  };
  const allSelectedHiddenNodes = getAllSelectedHiddenNodes(hiddenStateData);
  if (allSelectedHiddenNodes) {
    for (const node of allSelectedHiddenNodes) {
      result.statuscastServiceId.push(node.statuscastId);
      result.statuscastGroupId.push(node.statuscastGroupId);
      result.displayName.push(node.label);
    }
  }

  result.emails.push(email);

  return result;
};
