import React, { useState } from 'react';
import { Chip } from '@trimbleinc/modus-react-bootstrap';

interface Selected {
  label: string;
  nodeId: number;
}

interface ChipinputComponentProps {
  selected: Selected[];
  onCheckboxChange: (nodeId: number, checked: boolean) => void;
}

const ServiceChips: React.FC<ChipinputComponentProps> = ({
  selected,
  onCheckboxChange,
}) => {
  const [chipOpenState, setChipOpenState] = useState<boolean[]>(
    new Array(selected.length).fill(true)
  );

  const toggleChip = (index: number, nodeId: number) => {
    setChipOpenState((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
    onCheckboxChange(nodeId, false);
  };

  return (
    <div>
      {selected.map((value, index) => (
        <Chip
          key={index}
          label={value.label}
          onClose={() => toggleChip(index, value.nodeId)}
          show={chipOpenState[index]}
          variant="solid"
          type="input"
          className="mr-2"
          closeIcon={
            <i className="modus-icons" aria-hidden="true">
              cancel_circle
            </i>
          }
              style={{ margin: '5px' }}
            ></Chip>
          )
      )}
    </div>
  );
};

export default ServiceChips;
