import './../App';
import { useEffect, useState } from 'react';
import { getService } from '../Services/ServiceManagement';
import { Accordion, Card } from '@trimbleinc/modus-react-bootstrap';
import TreeNode from './TreeNode';
import { CenteredSpinnerCircle } from './CenteredSpinnerCircle';
import ServiceChips from './ServiceChips';
import MarkPreviousEntry from './MarkPreviousEntry';

type Service = {
  statuscastId: number;
  statuscastGroupId: number;
  displayName: string;
  isHidden: boolean;
  isMainComponent: boolean;
  children: Service[];
};

interface Selected {
  label: string;
  nodeId: number;
  statuscastServiceId: number;
}

interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  isMainComponent: boolean;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}

interface NodeWithHidden {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  isMainComponent: boolean;
  children?: NodeWithHidden[];
  isSelected: boolean;
  isHidden: boolean;
  isHiddenCount: number;
  childCount: number;
}

const FetchResponse: React.FC = () => {
  const [data, setData] = useState<Node[]>();
  const [hiddenData, setHiddenData] = useState<NodeWithHidden[]>();
  const [isExpand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getService();
      const [treeData, hiddenTreeData] = await Promise.all([
        convertToTreeItems(response.data),
        convertToTreeItemswithHidden(response.data),
      ]);

      setData(treeData);
      setHiddenData(hiddenTreeData);
    };
    fetchUser();
  }, []);

  // Function to convert Service data to TreeItem data
  const convertToTreeItems = async (serviceData: Service[]) => {
    let nodeIdCounter = 0;

    const convertItem = (serviceItem: Service): Node | null => {
      if (serviceItem.isHidden === false) {
        nodeIdCounter++;
        const treeItem: Node = {
          nodeId: nodeIdCounter,
          label: serviceItem.displayName,
          statuscastId: serviceItem.statuscastId,
          statuscastGroupId: serviceItem.statuscastGroupId,
          isSelected: false,
          isDisabled: false,
          isIndeterminate: false,
          isMainComponent: serviceItem.isMainComponent,
        };

        if (serviceItem.children && serviceItem.children.length > 0) {
          const childNodes = serviceItem.children
            .map(convertItem)
            .filter((child) => child !== null) as Node[];
          if (childNodes.length > 0) {
            treeItem.children = childNodes;
          }
        }

        return treeItem;
      }

      return null;
    };

    return serviceData
      .map(convertItem)
      .filter((item) => item !== null) as Node[]; // Filter out null entries
  };

  // Function to convert Service data to TreeItem data with hidden
  const convertToTreeItemswithHidden = async (serviceData: Service[]) => {
    let nodeIdCounter = 0;

    const convertItem = (serviceItem: Service): NodeWithHidden => {
      nodeIdCounter++;
      const treeItem: NodeWithHidden = {
        nodeId: nodeIdCounter,
        label: serviceItem.displayName,
        statuscastId: serviceItem.statuscastId,
        statuscastGroupId: serviceItem.statuscastGroupId,
        isMainComponent: serviceItem.isMainComponent,
        isSelected: false,
        isHidden: serviceItem.isHidden,
        isHiddenCount: 0,
        childCount: 0,
      };

      if (serviceItem.children && serviceItem.children.length > 0) {
        const childNodes = serviceItem.children.map(convertItem);
        treeItem.children = childNodes;
        treeItem.isHiddenCount = childNodes.reduce(
          (count, child) =>
            count + child.isHiddenCount + (child.isHidden ? 1 : 0),
          0
        );
        treeItem.childCount = childNodes.reduce(
          (count, child) => count + child.childCount + 1,
          0
        );
      }

      return treeItem;
    };

    return serviceData.map(convertItem);
  };

  const handleCheckboxChange = (nodeId: number, checked: boolean) => {
    if (data) {
      const updatedData = updateNodeCheckbox(data, nodeId, checked);
      setData(updatedData);
    }
  };

  const handleSubscriberFetch = (nodeId: number, checked: boolean) => {
    if (hiddenData) {
      const updatedData = updateNodewithHidden(hiddenData, nodeId, checked);
      setHiddenData(updatedData);
    }
  };

  function updateNodewithHidden(
    nodes: NodeWithHidden[],
    nodeId: number,
    checked: boolean
  ): NodeWithHidden[] {
    const updatedNodes = nodes.map((node) => {
      if (node.nodeId === nodeId) {
        node.isSelected = checked;
      } else if (node.children) {
        node.children = updateNodewithHidden(node.children, nodeId, checked);
      }
      return node;
    });

    return updatedNodes;
  }

  function updateNodeCheckbox(
    nodes: Node[],
    nodeId: number,
    checked: boolean
  ): Node[] {
    const updatedNodes = nodes.map((node) => {
      if (node.nodeId === nodeId) {
        node.isSelected = checked;
        if (node.children) {
          node.children = updateChildrenCheckbox(node.children, checked);
          if (!node.isMainComponent) {
            updateIntermediateState(node);
          }
        }
      } else if (node.children) {
        node.children = updateNodeCheckbox(node.children, nodeId, checked);
        if (!node.isMainComponent) {
          updateIntermediateState(node);
        }
      }
      return node;
    });

    return updatedNodes;
  }

  function updateChildrenCheckbox(children: Node[], checked: boolean): Node[] {
    return children.map((child) => {
      child.isSelected = checked;
      child.isDisabled = checked;
      if (checked) {
        child.isIndeterminate = !checked;
      }
      if (child.children) {
        child.children = updateChildrenCheckbox(child.children, checked);
      }
      return child;
    });
  }

  function updateIntermediateState(node: Node) {
    if (!node.children) {
      return;
    }

    let selectedCount = 0;
    let indeterminateCount = 0;

    for (const child of node.children) {
      if (child.isSelected) {
        selectedCount++;
      } else if (child.isIndeterminate) {
        indeterminateCount++;
      }
    }

    if (selectedCount === node.children.length) {
      node.isIndeterminate = false;
      node.isSelected = true;
      for (const child of node.children) {
        child.isDisabled = true;
      }
    } else if (selectedCount > 0 || indeterminateCount > 0) {
      node.isIndeterminate = true;
      node.isSelected = false;
    } else {
      node.isIndeterminate = false;
      node.isSelected = false;
    }
  }

  const getSelectedLabels = (nodes: Node[]): Selected[] => {
    const selectedLabels: Selected[] = [];

    const traverseNodes = (nodesToTraverse: Node[]) => {
      for (const node of nodesToTraverse) {
        if (node.isSelected && !node.isDisabled) {
          selectedLabels.push({
            label: node.label,
            nodeId: node.nodeId,
            statuscastServiceId: node.statuscastId,
          });
        }
        if (node.children) {
          traverseNodes(node.children);
        }
      }
    };

    traverseNodes(nodes);

    return selectedLabels;
  };

  let selectedLabels: Selected[] = [];
  if (data) {
    selectedLabels = getSelectedLabels(data);
  }

  function handlePlaceHolder(): void {
    setExpand(!isExpand);
  }

  return (
    <div className='custom-accordion'>
      {data ? ( // Check if data is available
        <div>
          <p className='custom-component-name'>Subscribed to</p>
          <ServiceChips
            selected={selectedLabels}
            onCheckboxChange={handleCheckboxChange}
          />
          {selectedLabels.length > 0 ? (
            <p style={{ marginBottom: '7px' }}></p>
          ) : (
            <p style={{ margin: '0px 0px 0px' }}></p>
          )}

          <Accordion defaultActiveKey='1'>
            <Card
              style={
                isExpand ? { boxShadow: '0 13px 20px rgba(0, 0, 0, 0.2)' } : {}
              }
            >
              <Accordion.Toggle
                as={Card.Header}
                eventKey='0'
                onClick={handlePlaceHolder}
              >
                <h6
                  className='mb-0'
                  id='collapsible-group-item-1'
                  style={{
                    fontWeight: 'normal',
                    color: '#464b52',
                    fontSize: '15px',
                  }}
                >
                  {isExpand ? '' : 'Select Services'}
                </h6>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='0'>
                <Card.Body className='custom-scroll'>
                  <div className='custom-card-bg'>
                    {data.map((node) => (
                      <TreeNode
                        key={node.nodeId}
                        node={node}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    ))}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <MarkPreviousEntry
            selected={selectedLabels}
            onCheckboxChange={handleCheckboxChange}
            onGetSubscriberFetch={handleSubscriberFetch}
            stateData={data}
            hiddenStateData={hiddenData}
          />
        </div>
      ) : (
        <div>
          <CenteredSpinnerCircle loadingText='Loading' />
          <br></br>
          <br></br>
        </div>
      )}
    </div>
  );
};

export default FetchResponse;
