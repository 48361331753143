import { Form } from '@trimbleinc/modus-react-bootstrap';
import React from 'react';
import { getSessionData, sessionConstants } from './storage/sessionStorage';
import jwt from 'jwt-decode';
import { userJwt } from 'types/userJwt';
declare interface EmailInputComponentProps {
  label?: String;
  inputMode?: 'input' | 'textarea' | 'select';
  size?: 'sm' | 'lg';
}

const EmailInput: React.FC<EmailInputComponentProps> = (
  props: EmailInputComponentProps
) => {
  // code to get user name to display profile details
  const idToken = getSessionData(sessionConstants.idToken);
  var user: userJwt = jwt(idToken !== null ? idToken : '');

  return (
    <div className="custom-flex-container">
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="label-lg">{props.label}</Form.Label>
          <Form.Control
            as={props.inputMode}
            size={props.size}
            placeholder="Email Address"
            value={user.email}
            readOnly
            style={{ background: '#dadce7' }}
          ></Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
};

export default EmailInput;
