import { AxiosRequestConfig } from 'axios';
import { executeRequest } from './httpService';
import { ServiceHttpClient } from './httpClients';
import { GetServiceError } from '../CustomErrors/getServiceError';
import path from 'config/urlPath';
import { getSessionData } from 'components/storage/sessionStorage';

export const getService = async () => {
  try {
    const appToken = getSessionData('token');
    const options: AxiosRequestConfig = {
      url: path.SERVICE_MANAGEMENT_URL.getAllServices,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${appToken}`,
      },
    };
    const response = await executeRequest(ServiceHttpClient, options);

    return response;
  } catch (error) {
    throw new GetServiceError(error);
  }
};
