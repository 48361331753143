import { Button } from '@trimbleinc/modus-react-bootstrap';
import React, { useState } from 'react';

interface TreeNodeProps {
  node: Node;
  level?: number;
  onCheckboxChange: (nodeId: number, checked: boolean) => void;
  isMainParent?: boolean;
}

interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  isMainComponent: boolean;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  level = 0,
  onCheckboxChange,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleCheckbox = () => {
    onCheckboxChange(node.nodeId, !node.isSelected);
  };

  const paddingLeft = level * 5; // Adjust the indentation level here

  const treeNodeStyle = {
    paddingLeft: node.children ? paddingLeft : paddingLeft + 11.5,
    fontSize: '20px',
    paddingDown: '4px',
  };

  const checkboxStyle = {
    marginRight: '8px',
    width: '18px',
    height: '18px',
    verticalAlign: 'top',
  };

  const checkboxStyle1 = {
    marginRight: '8px',
    width: '18px',
    height: '18px',
  };

  const buttonStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: '0px',
    width: '24',
    height: '24',
    verticalAlign: 'bottom',
  };

  return (
    <div style={treeNodeStyle}>
      <div style={treeNodeStyle}>
        <label className="custom-tree-label">
          {node.children ? (
            <span>
              <button style={buttonStyle} onClick={toggleExpanded}>
                <i
                  className="modus-icons"
                  aria-hidden="true"
                  style={{ fontSize: '20px', paddingRight: '3px' }}
                >
                  {expanded ? 'expand_less' : 'expand_more'}
                </i>
              </button>
              {!node.isMainComponent && (
              <input
                style={checkboxStyle}
                type="checkbox"
                checked={node.isSelected}
                disabled={node.isDisabled}
                onChange={toggleCheckbox}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = node.isIndeterminate;
                  }
                }}
              />
              )}
            </span>
          ) : (
            <span>
              {!node.isMainComponent && (
              <input
                style={checkboxStyle1}
                type="checkbox"
                checked={node.isSelected}
                disabled={node.isDisabled}
                onChange={toggleCheckbox}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = node.isIndeterminate;
                  }
                }}
              />
              )}
            </span>
          )}

          <span style={{ verticalAlign: 'top' }}>{node.label}</span>
        </label>
      </div>
      {expanded && node.children && (
        <div style={{ marginLeft: '10px' }}>
          {node.children.map((child) => (
            <TreeNode
              key={child.nodeId}
              node={child}
              level={level + 1}
              onCheckboxChange={onCheckboxChange}
              isMainParent={false}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeNode;
