import Spinner from '@trimbleinc/modus-react-bootstrap/esm/Spinner';

export interface SpinnerCircleProps {
  loadingText: string;
}

export const CenteredSpinnerCircle = ({ loadingText }: SpinnerCircleProps) => {
  return (
    <div className="px-3">
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" role="status" size="sm" />
        <div className="h3" style={{marginLeft:'5px'}}>{loadingText}</div>
      </div>
    </div>
  );
};
