import { Alert } from '@trimbleinc/modus-react-bootstrap';
import { clearSessionData } from 'components/storage/sessionStorage';
import { messages } from 'types/messages';

export const Error = () => {
  clearSessionData();
  return (
    <div className="m-4">
      <Alert variant="danger" dismissible={false}>
        <i className="modus-icons notranslate alert-icon" aria-hidden="true">
          warning
        </i>
        {messages.LOGIN_FAILURE}
      </Alert>
    </div>
  );
};
